<template>
  <v-container class="size mt-9">
    <v-subheader class="title mb-4 text-uppercase pa-0 justify-space-between">
      <div>
        <v-icon class="mr-3" @click="$router.push('/pdv')"
          >mdi-arrow-left</v-icon
        >
        {{ "Gerenciar BPes não enviados" }}
      </div>
      <v-btn dark color="primary" :disabled="bpes.length === 0" @click="sendAll"
        >Enviar Todos</v-btn
      >
    </v-subheader>
    <template v-if="bpes.length > 0">
      <v-card
        class="mb-10"
        v-for="(item, i) in bpes"
        :key="i"
        @click="getBpe(item.id)"
      >
        <v-row dense>
          <v-col cols="12" sm="12">
            <v-btn text color="primary" disabled>
              <span style="color: #000">BPe N° {{ item.id }}</span>
            </v-btn>
            <span v-if="item.erros">-</span>
            <v-btn
              color="primary"
              v-if="item.erros"
              text
              dark
              @click="[(erros = true), (error = item.erros)]"
              >Ver Detalhes do Erro</v-btn
            >
          </v-col>
          <v-col cols="12" sm="12">
            <v-btn text color="primary" disabled>
              <span style="color: #000">Situação: {{ item.xmotivo }}</span>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="3"></v-col>
          <v-col cols="12" sm="3"></v-col>
          <v-col cols="12" sm="3"></v-col>
        </v-row>
        <v-card-actions style="background: #2196f3"></v-card-actions>
      </v-card>
    </template>
    <template v-else>
      <v-card class="text-center" outlined color="primary" dark>
        <v-card-title>
          <v-icon class="mr-5">mdi-emoticon</v-icon>Não foram encontrados BPes
          não enviados.
        </v-card-title>
      </v-card>
    </template>
    <v-dialog v-model="erros" persistent>
      <v-card>
        <v-card-title class="headline grey lighten-2 mb-3" primary-title
          >Erros no BPe</v-card-title
        >
        <v-card-text>{{ error }}</v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="erros = false">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="dialog" persistent style="z-index: 888">
      <v-card>
        <v-form
          ref="form"
          v-model="valid"
          lazy-validation
          autocomplete="off"
          @submit.prevent
        >
          <v-card-title>
            <span>DADOS DO BPe N° {{ bpe.id }}</span>
          </v-card-title>

          <v-card-text>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>IDE</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Cod UF do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.cUF"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Tipo de Ambiente</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.ide.tpAmb"
                      :rules="fieldRules"
                      :items="[
                        { id: '1', desc: 'PRODUÇÃO' },
                        { id: '2', desc: 'HOMOLOGAÇÃO' },
                      ]"
                      item-value="id"
                      item-text="desc"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text"
                      >Modelo do Bilhete de Passagem</span
                    >
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.mod"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Serie</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.serie"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Cod BPe</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.cBP"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Digito Verificador</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.cDV"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Modalidade de Transporte</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.ide.modal"
                      :rules="fieldRules"
                      :items="[
                        { id: '1', desc: 'RODOVIÁRIO' },
                        { id: '3', desc: 'AQUAVIÁRIO' },
                        { id: '4', desc: 'FERROVIÁRIO' },
                      ]"
                      item-value="id"
                      item-text="desc"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Data/Hora Emissão</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.dhEmi"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Tipo de Emissão</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.ide.tpEmis"
                      :rules="fieldRules"
                      :items="[
                        { id: '1', desc: 'NORMAL' },
                        { id: '2', desc: 'CONTIGENCIA OFFLINE' },
                      ]"
                      item-value="id"
                      item-text="desc"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Versão do APP</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.verProc"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span class="primary--text">Indicador de Presença</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.ide.indPres"
                      :rules="fieldRules"
                      :items="[
                        { id: '1', desc: 'Operação presencial não embarcado' },
                        {
                          id: '2',
                          desc: 'Operação não presencial, pela Internet',
                        },
                        {
                          id: '3',
                          desc: 'Operação não presencial,Teleatendimento',
                        },
                        {
                          id: '4',
                          desc: 'BP-e em operação com entrega a domicílio',
                        },
                        { id: '5', desc: 'Operação presencial embarcada' },
                        { id: '9', desc: 'Operação não presencial, outros' },
                      ]"
                      item-value="id"
                      item-text="desc"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">UF Município Inicial</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.UFIni"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Cod Município Inicial</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.cMunIni"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">UF Município Final</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.UFFim"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Cod Município Final</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.ide.cMunFim"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>Informação da Passagem</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7">
                <v-row>
                  <v-col cols="12" sm="3">
                    <span class="primary--text">Cod Localidade Origem</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.cLocOrig"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <span class="primary--text"
                      >Descrição Localidade Origem</span
                    >
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.xLocOrig"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="3">
                    <span class="primary--text">Cod Localidade Destino</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.cLocDest"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="9">
                    <span class="primary--text"
                      >Descrição Localidade Destino</span
                    >
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.xLocDest"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Data/Hora Embarque</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.dhEmb"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Data/Hora Validade</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infPassagem.dhValidade"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Nome do Passageiro</span>
                    <v-text-field
                      v-model="
                        dataBPe.BPe.infBPe.infPassagem.infPassageiro.xNome
                      "
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text"
                      >Tipo de Documento do Passageiro</span
                    >
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="
                        dataBPe.BPe.infBPe.infPassagem.infPassageiro.tpDoc
                      "
                      :items="tiposDocumentos"
                      item-value="id"
                      item-text="desc"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text"
                      >Nome do Documento do Passageiro</span
                    >
                    <v-text-field
                      v-model="
                        dataBPe.BPe.infBPe.infPassagem.infPassageiro.nDoc
                      "
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>EMIT</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="8">
                    <span class="primary--text">Nome do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.xNome"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">CNPJ do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.CNPJ"
                      :rules="fieldRules"
                      required
                      v-mask-cnpj
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">IE do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.IE"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">IM do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.IM"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">CNAE do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.CNAE"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">CRT do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.CRT"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">TAR do Emitente</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.TAR"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Logradouro</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.xLgr"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Número</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.nro"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Bairro</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.xBairro"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Código do Município</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.cMun"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Descrição do Município</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.xMun"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">UF</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.emit.enderEmit.UF"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>Agência</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="8">
                    <span class="primary--text">Nome da Agência</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.xNome"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Nome da CNPJ</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.CNPJ"
                      :rules="fieldRules"
                      required
                      v-mask-cnpj
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Logradouro</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.xLgr"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Número</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.nro"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Bairro</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.xBairro"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Código do Município</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.cMun"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Descrição do Município</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.xMun"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">UF</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.agencia.enderAgencia.UF"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>Informação da Viagem</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Código do Percuso</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infViagem.cPercurso"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="8">
                    <span class="primary--text">Descrição do Percuso</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infViagem.xPercurso"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Tipo de Viagem</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infViagem.tpViagem"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Tipo de Serviço</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.infViagem.tpServ"
                      :items="tiposServico"
                      item-value="id"
                      item-text="tipo_servico"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Tipo de Acomodação</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.infViagem.tpAcomodacao"
                      :items="[
                        { id: '1', desc: 'Assento/poltrona' },
                        { id: '2', desc: 'Rede' },
                        { id: '3', desc: 'Rede com ar-condicionado' },
                        { id: '4', desc: 'Cabine' },
                        { id: '5', desc: 'Outros' },
                      ]"
                      item-value="id"
                      item-text="desc"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Tipo de Trecho</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.infViagem.tpTrecho"
                      :items="[{ id: '1', desc: 'NORMAL' }]"
                      item-value="id"
                      item-text="desc"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="6">
                    <span class="primary--text">Data/Hora Viagem</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infViagem.dhViagem"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>Informação do Valor do BP-e</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor do BPe</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infValorBPe.vBP"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor do Desconto</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infValorBPe.vDesconto"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor Pago</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infValorBPe.vPgto"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor do Troco</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.infValorBPe.vTroco"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                </v-row>
                <span v-if="dataBPe.BPe.infBPe.infValorBPe.Comp[0]">
                  <v-row
                    v-for="(itens, i) in dataBPe.BPe.infBPe.infValorBPe.Comp"
                    :key="i"
                  >
                    <v-col cols="12" sm="3">
                      <span class="primary--text">Composição da Compra</span>
                      <v-select
                        solo
                        class="mt-1 custom-solo-selection"
                        v-model="itens.tpComp"
                        @input="setTpComp(itens.tpComp, i)"
                        :items="[
                          { id: '01', desc: 'TARIFA' },
                          { id: '02', desc: 'PEDÁGIO' },
                          { id: '03', desc: 'TAXA EMBARQUE' },
                          { id: '04', desc: 'SEGURO' },
                        ]"
                        item-value="id"
                        item-text="desc"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <span class="primary--text"
                        >Composição da Compra Valor</span
                      >
                      <v-text-field
                        v-model="itens.vComp"
                        @input="setVComp(itens.vComp, i)"
                        :rules="fieldRules"
                        required
                        class="mt-n1"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </span>
                <span v-else>
                  <v-row>
                    <v-col cols="12" sm="3">
                      <span class="primary--text">Composição da Compra</span>
                      <v-select
                        solo
                        class="mt-1 custom-solo-selection"
                        v-model="dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp"
                        :items="[
                          { id: '01', desc: 'TARIFA' },
                          { id: '02', desc: 'PEDÁGIO' },
                          { id: '03', desc: 'TAXA EMBARQUE' },
                          { id: '04', desc: 'SEGURO' },
                        ]"
                        item-value="id"
                        item-text="desc"
                      ></v-select>
                    </v-col>
                    <v-col cols="12" sm="2">
                      <span class="primary--text"
                        >Composição da Compra Valor</span
                      >
                      <v-text-field
                        v-model="dataBPe.BPe.infBPe.infValorBPe.Comp.vComp"
                        :rules="fieldRules"
                        required
                        class="mt-n1"
                      ></v-text-field>
                    </v-col>
                  </v-row> </span
              ></v-card-text>
            </v-card>
            <v-card class="mb-5">
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>IMP</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">CST</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.imp.ICMS.ICMS00.CST"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor da Base de Cálculo</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vBC"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Percentual ICMS</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.imp.ICMS.ICMS00.pICMS"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor ICMS</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vICMS"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Simples Nacional</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      v-model="dataBPe.BPe.infBPe.imp.ICMS.ICMS00.indSN"
                      :items="[
                        { id: '0', desc: 'NÃO' },
                        { id: '1', desc: 'SIM' },
                      ]"
                      item-value="id"
                      item-text="desc"
                    ></v-select>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
            <v-card>
              <v-card-title
                class="primary white--text d-flex justify-space-between"
              >
                <span>PAG</span>
                <span>Campos Obrigatórios *</span>
              </v-card-title>

              <v-card-text class="pt-7 px-7"
                ><v-row>
                  <v-col cols="12" sm="4">
                    <span class="primary--text">Forma de Pagamento</span>
                    <v-select
                      solo
                      class="mt-1 custom-solo-selection"
                      :items="formasPagamento"
                      v-model="dataBPe.BPe.infBPe.pag.tPag"
                      :rules="fieldRules"
                      item-value="id"
                      item-text="descricao"
                      required
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="2">
                    <span class="primary--text">Valor Pago</span>
                    <v-text-field
                      v-model="dataBPe.BPe.infBPe.pag.vPag"
                      :rules="fieldRules"
                      required
                      class="mt-n1"
                    ></v-text-field>
                  </v-col> </v-row
              ></v-card-text>
            </v-card>
          </v-card-text>

          <v-card-actions class="d-flex justify-end primary mx-4 rounded-t">
            <v-btn dark depressed text @click="[(dialog = false), clearForm()]"
              >Cancelar</v-btn
            >
            <v-btn dark depressed text :disabled="!valid" @click="validate"
              >Confirmar</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
    <v-snackbar
      v-model="message.snacshow"
      :color="message.type"
      :multi-line="message.mode === 'multi-line'"
      :timeout="message.timeout"
      :vertical="message.mode === 'vertical'"
      bottom
    >
      {{ message.text }}
      <v-btn dark text @click="message.snacshow = false">
        <v-icon small>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </v-container>
</template>

<script>
export default {
  data: () => ({
    cancelDialog: false,
    childDialog: false,
    dialog: false,
    bpes: [],
    items: [],
    bpe: [],
    error: null,
    erros: false,
    tiposDocumentos: [
      { id: "1", desc: "RG" },
      { id: "2", desc: "TÍTULO DE ELEITOR" },
      { id: "3", desc: "PASSAPORTE" },
      { id: "4", desc: "CNH" },
      { id: "5", desc: "OUTROS" },
    ],
    dadosBpe: {
      composicao: [],
      agencia: { enderAgencia: {} },
      emit: { enderEmit: {} },
      ide: {},
      infPassagem: { infPassageiro: {} },
    },
    setTpComp(event, i) {
      this.dadosBpe.composicao[0][i].tpComp = event;
    },
    setVComp(event, i) {
      this.dadosBpe.composicao[0][i].vComp = event;
    },
    formasPagamento: [],
    tiposServico: [],
    agencias: [],
    dataBPe: {
      BPe: {
        infBPe: {
          versao: "1.00",
          ide: {
            cUF: "",
            tpAmb: "",
            mod: "",
            serie: "",
            nBP: "",
            cBP: "",
            cDV: "",
            modal: "",
            dhEmi: "",
            tpEmis: "",
            verProc: "",
            tpBPe: "0",
            indPres: "",
            UFIni: "",
            cMunIni: "",
            UFFim: "",
            cMunFim: "",
          },
          emit: {
            CNPJ: "",
            IE: "",
            xNome: "",
            IM: "",
            CNAE: "",
            CRT: "",
            enderEmit: {
              xLgr: "",
              nro: "",
              xBairro: "",
              cMun: "",
              xMun: "",
              UF: "",
            },
            TAR: "",
          },
          agencia: {
            xNome: "",
            CNPJ: "",
            enderAgencia: {
              xLgr: "",
              nro: "",
              xBairro: "",
              cMun: "",
              xMun: "",
              UF: "",
            },
          },
          infPassagem: {
            cLocOrig: "",
            xLocOrig: "",
            cLocDest: "",
            xLocDest: "",
            dhEmb: "",
            infPassageiro: {
              xNome: "",
              tpDoc: "",
              nDoc: "",
            },
          },
          infViagem: {
            cPercurso: "",
            xPercurso: "",
            tpViagem: "",
            tpServ: "",
            tpAcomodacao: "",
            tpTrecho: "",
            dhViagem: "",
          },
          infValorBPe: {
            vBP: "",
            vDesconto: "",
            tpDesconto: "0",
            vPgto: "",
            vTroco: "0.00",
            Comp: [],
            xDesconto: "",
          },
          imp: {
            ICMS: {
              ICMS00: {},
            },
          },
          pag: {
            tPag: "",
            vPag: "",
          },
        },
      },
    },

    search: {
      typeSearch: "",
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      document: "",
      bar_code: "",
      id_agencia: 0,
    },
    tiposDocumento: [
      { id: "1", desc: "RG" },
      { id: "2", desc: "TÍTULO DE ELEITOR" },
      { id: "3", desc: "PASSAPORTE" },
      { id: "4", desc: "CNH" },
      { id: "5", desc: "OUTROS" },
    ],
    message: {
      snacshow: false,
      text: "",
      type: "",
      mode: "",
      timeout: 3000,
    },
    dadosPassagem: [],
    valid: true,
    fieldRules: [(v) => !!v || "Este campo é requerido"],
  }),
  created() {
    this.initialize();
    this.getFormasPagamentos();
    this.getTiposDeServico();
    this.getAgencias();
  },

  methods: {
    getBpe(id) {
      this.$http.get(`/bpe/${id}`).then((r) => {
        this.bpe = r.data[0];
        this.dataBPe.id = this.bpe.id;
        this.dataBPe.BPe.infBPe.infValorBPe.Comp = [];

        this.dataBPe.BPe.infBPe.versao = this.bpe.versao;
        this.dataBPe.BPe.infBPe.ide.cUF = this.bpe.bpe.infBPe.ide.cUF;
        this.dataBPe.BPe.infBPe.ide.tpAmb = this.bpe.bpe.infBPe.ide.tpAmb;
        this.dataBPe.BPe.infBPe.ide.mod = this.bpe.bpe.infBPe.ide.mod;
        this.dataBPe.BPe.infBPe.ide.serie = this.bpe.bpe.infBPe.ide.serie;
        this.dataBPe.BPe.infBPe.ide.nBP = this.bpe.bpe.infBPe.ide.nBP;
        this.dataBPe.BPe.infBPe.ide.cBP = this.bpe.bpe.infBPe.ide.cBP;
        this.dataBPe.BPe.infBPe.ide.cDV = this.bpe.bpe.infBPe.ide.cDV;
        this.dataBPe.BPe.infBPe.ide.modal = this.bpe.bpe.infBPe.ide.modal;
        this.dataBPe.BPe.infBPe.ide.dhEmi = this.bpe.bpe.infBPe.ide.dhEmi;
        this.dataBPe.BPe.infBPe.ide.tpEmis = this.bpe.bpe.infBPe.ide.tpEmis;
        this.dataBPe.BPe.infBPe.ide.tpBpe = this.bpe.bpe.infBPe.ide.tpBpe;
        this.dataBPe.BPe.infBPe.ide.verProc = this.bpe.bpe.infBPe.ide.verProc;
        this.dataBPe.BPe.infBPe.ide.indPres = this.bpe.bpe.infBPe.ide.indPres;
        this.dataBPe.BPe.infBPe.ide.UFIni = this.bpe.bpe.infBPe.ide.UFIni;
        this.dataBPe.BPe.infBPe.ide.cMunIni = this.bpe.bpe.infBPe.ide.cMunIni;
        this.dataBPe.BPe.infBPe.ide.UFFim = this.bpe.bpe.infBPe.ide.UFFim;
        this.dataBPe.BPe.infBPe.ide.cMunFim = this.bpe.bpe.infBPe.ide.cMunFim;

        this.dataBPe.BPe.infBPe.emit.CNPJ = this.bpe.bpe.infBPe.emit.CNPJ;
        this.dataBPe.BPe.infBPe.emit.IE = this.bpe.bpe.infBPe.emit.IE;
        this.dataBPe.BPe.infBPe.emit.xNome = this.bpe.bpe.infBPe.emit.xNome;
        this.dataBPe.BPe.infBPe.emit.IM = this.bpe.bpe.infBPe.emit.IM;
        this.dataBPe.BPe.infBPe.emit.CNAE = this.bpe.bpe.infBPe.emit.CNAE;
        this.dataBPe.BPe.infBPe.emit.CRT = this.bpe.bpe.infBPe.emit.CRT;
        this.dataBPe.BPe.infBPe.emit.TAR = this.bpe.bpe.infBPe.emit.TAR;
        this.dataBPe.BPe.infBPe.emit.enderEmit.xLgr =
          this.bpe.bpe.infBPe.emit.enderEmit.xLgr;
        this.dataBPe.BPe.infBPe.emit.enderEmit.nro =
          this.bpe.bpe.infBPe.emit.enderEmit.nro;
        this.dataBPe.BPe.infBPe.emit.enderEmit.xBairro =
          this.bpe.bpe.infBPe.emit.enderEmit.xBairro;
        this.dataBPe.BPe.infBPe.emit.enderEmit.cMun =
          this.bpe.bpe.infBPe.emit.enderEmit.cMun;
        this.dataBPe.BPe.infBPe.emit.enderEmit.xMun =
          this.bpe.bpe.infBPe.emit.enderEmit.xMun;
        this.dataBPe.BPe.infBPe.emit.enderEmit.UF =
          this.bpe.bpe.infBPe.emit.enderEmit.UF;

        this.dataBPe.BPe.infBPe.agencia.xNome =
          this.bpe.bpe.infBPe.agencia.xNome;
        this.dataBPe.BPe.infBPe.agencia.CNPJ = this.bpe.bpe.infBPe.agencia.CNPJ;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.xLgr =
          this.bpe.bpe.infBPe.agencia.enderAgencia.xLgr;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.nro =
          this.bpe.bpe.infBPe.agencia.enderAgencia.nro;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.xBairro =
          this.bpe.bpe.infBPe.agencia.enderAgencia.xBairro;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.cMun =
          this.bpe.bpe.infBPe.agencia.enderAgencia.cMun;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.xMun =
          this.bpe.bpe.infBPe.agencia.enderAgencia.xMun;
        this.dataBPe.BPe.infBPe.agencia.enderAgencia.UF =
          this.bpe.bpe.infBPe.agencia.enderAgencia.UF;

        this.dataBPe.BPe.infBPe.infPassagem.cLocOrig =
          this.bpe.bpe.infBPe.infPassagem.cLocOrig;
        this.dataBPe.BPe.infBPe.infPassagem.xLocOrig =
          this.bpe.bpe.infBPe.infPassagem.xLocOrig;
        this.dataBPe.BPe.infBPe.infPassagem.cLocDest =
          this.bpe.bpe.infBPe.infPassagem.cLocDest;
        this.dataBPe.BPe.infBPe.infPassagem.xLocDest =
          this.bpe.bpe.infBPe.infPassagem.xLocDest;
        this.dataBPe.BPe.infBPe.infPassagem.dhEmb =
          this.bpe.bpe.infBPe.infPassagem.dhEmb;
        this.dataBPe.BPe.infBPe.infPassagem.dhValidade =
          this.bpe.bpe.infBPe.infPassagem.dhValidade;
        this.dataBPe.BPe.infBPe.infPassagem.infPassageiro.xNome =
          this.bpe.bpe.infBPe.infPassagem.infPassageiro.xNome;
        this.dataBPe.BPe.infBPe.infPassagem.infPassageiro.tpDoc =
          this.bpe.bpe.infBPe.infPassagem.infPassageiro.tpDoc;
        this.dataBPe.BPe.infBPe.infPassagem.infPassageiro.nDoc =
          this.bpe.bpe.infBPe.infPassagem.infPassageiro.nDoc;

        this.dataBPe.BPe.infBPe.infViagem.cPercurso =
          this.bpe.bpe.infBPe.infViagem.cPercurso;
        this.dataBPe.BPe.infBPe.infViagem.xPercurso =
          this.bpe.bpe.infBPe.infViagem.xPercurso;
        this.dataBPe.BPe.infBPe.infViagem.tpViagem =
          this.bpe.bpe.infBPe.infViagem.tpViagem;
        this.dataBPe.BPe.infBPe.infViagem.tpServ =
          this.bpe.bpe.infBPe.infViagem.tpServ;
        this.dataBPe.BPe.infBPe.infViagem.tpAcomodacao =
          this.bpe.bpe.infBPe.infViagem.tpAcomodacao;
        this.dataBPe.BPe.infBPe.infViagem.tpTrecho =
          this.bpe.bpe.infBPe.infViagem.tpTrecho;
        this.dataBPe.BPe.infBPe.infViagem.dhViagem =
          this.bpe.bpe.infBPe.infViagem.dhViagem;

        this.dataBPe.BPe.infBPe.infValorBPe.vBP =
          this.bpe.bpe.infBPe.infValorBPe.vBP;
        this.dataBPe.BPe.infBPe.infValorBPe.tpDesconto =
          this.bpe.bpe.infBPe.infValorBPe.tpDesconto;
        this.dataBPe.BPe.infBPe.infValorBPe.xDesconto =
          this.bpe.bpe.infBPe.infValorBPe.xDesconto;
        this.dataBPe.BPe.infBPe.infValorBPe.vDesconto =
          this.bpe.bpe.infBPe.infValorBPe.vDesconto;
        this.dataBPe.BPe.infBPe.infValorBPe.vPgto =
          this.bpe.bpe.infBPe.infValorBPe.vPgto;
        this.dataBPe.BPe.infBPe.infValorBPe.vTroco =
          this.bpe.bpe.infBPe.infValorBPe.vTroco;

        if (this.bpe.bpe.infBPe.infValorBPe.Comp.length) {
          for (
            let index = 0;
            index < this.bpe.bpe.infBPe.infValorBPe.Comp.length;
            index++
          ) {
            this.dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp =
              this.bpe.bpe.infBPe.infValorBPe.Comp[index].tpComp;
            this.dataBPe.BPe.infBPe.infValorBPe.Comp.vComp =
              this.bpe.bpe.infBPe.infValorBPe.Comp[index].vComp;
            this.dataBPe.BPe.infBPe.infValorBPe.Comp.push({
              tpComp: this.dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp,
              vComp: this.dataBPe.BPe.infBPe.infValorBPe.Comp.vComp,
            });
          }
        } else {
          this.dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp =
            this.bpe.bpe.infBPe.infValorBPe.Comp.tpComp;
          this.dataBPe.BPe.infBPe.infValorBPe.Comp.vComp =
            this.bpe.bpe.infBPe.infValorBPe.Comp.vComp;
          this.dataBPe.BPe.infBPe.infValorBPe.Comp.push({
            tpComp: this.dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp,
            vComp: this.dataBPe.BPe.infBPe.infValorBPe.Comp.vComp,
          });
        }

        this.dataBPe.BPe.infBPe.imp.ICMS.ICMS00.CST =
          this.bpe.bpe.infBPe.imp.ICMS.ICMS00.CST;
        this.dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vBC =
          this.bpe.bpe.infBPe.imp.ICMS.ICMS00.vBC;
        this.dataBPe.BPe.infBPe.imp.ICMS.ICMS00.pICMS =
          this.bpe.bpe.infBPe.imp.ICMS.ICMS00.pICMS;
        this.dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vICMS =
          this.bpe.bpe.infBPe.imp.ICMS.ICMS00.vICMS;
        this.dataBPe.BPe.infBPe.imp.ICMS.ICMS00.indSN =
          this.bpe.bpe.infBPe.imp.ICMS.ICMS00.indSN;

        this.dataBPe.BPe.infBPe.pag.tPag = parseInt(
          this.bpe.bpe.infBPe.pag.tPag
        );

        this.dataBPe.BPe.infBPe.pag.tPag = String(
          this.dataBPe.BPe.infBPe.pag.tPag
        );
        this.dataBPe.BPe.infBPe.pag.vPag = this.bpe.bpe.infBPe.pag.vPag;

        this.dialog = true;
        this.$store.dispatch("activeLoader", false);
      });
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.salvar();
      } else {
        this.message.snacshow = true;
        this.message.type = "warning";
        this.message.text =
          "Atencão um ou mais campos precisam ser preenchidos!";
      }
    },
    initialize() {
      this.$store.dispatch("activeLoader", true);
      this.search.id_agencia = this.$store.getters.getOperador;
      this.$http.get(`/bpe-errors`).then((r) => {
        this.bpes = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getClientes() {
      this.$http.get(`/clientes/crianca`).then((r) => {
        this.clientes = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getFormasPagamentos() {
      this.$http.get(`/formaspagamento`).then((r) => {
        this.formasPagamento = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getAgencias() {
      this.$http.get(`/agencias`).then((r) => {
        this.agencias = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    getTiposDeServico() {
      this.$http.get(`/tiposservico`).then((r) => {
        this.tiposServico = r.data;
        this.$store.dispatch("activeLoader", false);
      });
    },
    clearForm() {
      (this.dadosBpe.nome = ""),
        (this.dadosBpe.tipo_documento = ""),
        (this.dadosBpe.documento = "");
    },
    salvar() {
      this.$store.dispatch("activeLoader", true);
      this.$http
        .post("/bpe/reemissao-por-erro", this.dataBPe)
        .then((r) => {
          this.$store.dispatch("activeLoader", false);
          if (r.data.result) {
            this.dialog = false;
            this.message.text = r.data.result;
            this.message.snacshow = true;
            this.message.type = "success";
            setTimeout(() => {
              this.initialize();
            }, 800);
          } else if (r.data.error) {
            this.message.text = r.data.error;
            this.message.snacshow = true;
            this.message.type = "error";
          }
        })
        .catch((e) => {
          this.$store.dispatch("activeLoader", false);
          this.message.text = e;
          this.message.snacshow = true;
          this.message.type = "error";
        });
    },
    convertDate(date) {
      return date.split("-").reverse().join("/");
    },
    sendAll() {
      this.$store.dispatch("activeLoader", true);

      for (const item of this.bpes) {
        let bpe = [];
        let dataBPe = {
          BPe: {
            infBPe: {
              versao: "1.00",
              ide: {
                cUF: "",
                tpAmb: "",
                mod: "",
                serie: "",
                nBP: "",
                cBP: "",
                cDV: "",
                modal: "",
                dhEmi: "",
                tpEmis: "",
                verProc: "",
                tpBPe: "0",
                indPres: "",
                UFIni: "",
                cMunIni: "",
                UFFim: "",
                cMunFim: "",
              },
              emit: {
                CNPJ: "",
                IE: "",
                xNome: "",
                IM: "",
                CNAE: "",
                CRT: "",
                enderEmit: {
                  xLgr: "",
                  nro: "",
                  xBairro: "",
                  cMun: "",
                  xMun: "",
                  UF: "",
                },
                TAR: "",
              },
              agencia: {
                xNome: "",
                CNPJ: "",
                enderAgencia: {
                  xLgr: "",
                  nro: "",
                  xBairro: "",
                  cMun: "",
                  xMun: "",
                  UF: "",
                },
              },
              infPassagem: {
                cLocOrig: "",
                xLocOrig: "",
                cLocDest: "",
                xLocDest: "",
                dhEmb: "",
                infPassageiro: {
                  xNome: "",
                  tpDoc: "",
                  nDoc: "",
                },
              },
              infViagem: {
                cPercurso: "",
                xPercurso: "",
                tpViagem: "",
                tpServ: "",
                tpAcomodacao: "",
                tpTrecho: "",
                dhViagem: "",
              },
              infValorBPe: {
                vBP: "",
                vDesconto: "",
                tpDesconto: "0",
                vPgto: "",
                vTroco: "0.00",
                Comp: [],
                xDesconto: "",
              },
              imp: {
                ICMS: {
                  ICMS00: {},
                },
              },
              pag: {
                tPag: "",
                vPag: "",
              },
            },
          },
        };

        this.$http.get(`/bpe/${item.id}`).then((r) => {
          bpe = r.data[0];
          dataBPe.id = bpe.id;
          dataBPe.BPe.infBPe.infValorBPe.Comp = [];

          dataBPe.BPe.infBPe.versao = bpe.versao;
          dataBPe.BPe.infBPe.ide.cUF = bpe.bpe.infBPe.ide.cUF;
          dataBPe.BPe.infBPe.ide.tpAmb = bpe.bpe.infBPe.ide.tpAmb;
          dataBPe.BPe.infBPe.ide.mod = bpe.bpe.infBPe.ide.mod;
          dataBPe.BPe.infBPe.ide.serie = bpe.bpe.infBPe.ide.serie;
          dataBPe.BPe.infBPe.ide.nBP = bpe.bpe.infBPe.ide.nBP;
          dataBPe.BPe.infBPe.ide.cBP = bpe.bpe.infBPe.ide.cBP;
          dataBPe.BPe.infBPe.ide.cDV = bpe.bpe.infBPe.ide.cDV;
          dataBPe.BPe.infBPe.ide.modal = bpe.bpe.infBPe.ide.modal;
          dataBPe.BPe.infBPe.ide.dhEmi = bpe.bpe.infBPe.ide.dhEmi;
          dataBPe.BPe.infBPe.ide.tpEmis = bpe.bpe.infBPe.ide.tpEmis;
          dataBPe.BPe.infBPe.ide.tpBpe = bpe.bpe.infBPe.ide.tpBpe;
          dataBPe.BPe.infBPe.ide.verProc = bpe.bpe.infBPe.ide.verProc;
          dataBPe.BPe.infBPe.ide.indPres = bpe.bpe.infBPe.ide.indPres;
          dataBPe.BPe.infBPe.ide.UFIni = bpe.bpe.infBPe.ide.UFIni;
          dataBPe.BPe.infBPe.ide.cMunIni = bpe.bpe.infBPe.ide.cMunIni;
          dataBPe.BPe.infBPe.ide.UFFim = bpe.bpe.infBPe.ide.UFFim;
          dataBPe.BPe.infBPe.ide.cMunFim = bpe.bpe.infBPe.ide.cMunFim;

          dataBPe.BPe.infBPe.emit.CNPJ = bpe.bpe.infBPe.emit.CNPJ;
          dataBPe.BPe.infBPe.emit.IE = bpe.bpe.infBPe.emit.IE;
          dataBPe.BPe.infBPe.emit.xNome = bpe.bpe.infBPe.emit.xNome;
          dataBPe.BPe.infBPe.emit.IM = bpe.bpe.infBPe.emit.IM;
          dataBPe.BPe.infBPe.emit.CNAE = bpe.bpe.infBPe.emit.CNAE;
          dataBPe.BPe.infBPe.emit.CRT = bpe.bpe.infBPe.emit.CRT;
          dataBPe.BPe.infBPe.emit.TAR = bpe.bpe.infBPe.emit.TAR;
          dataBPe.BPe.infBPe.emit.enderEmit.xLgr =
            bpe.bpe.infBPe.emit.enderEmit.xLgr;
          dataBPe.BPe.infBPe.emit.enderEmit.nro =
            bpe.bpe.infBPe.emit.enderEmit.nro;
          dataBPe.BPe.infBPe.emit.enderEmit.xBairro =
            bpe.bpe.infBPe.emit.enderEmit.xBairro;
          dataBPe.BPe.infBPe.emit.enderEmit.cMun =
            bpe.bpe.infBPe.emit.enderEmit.cMun;
          dataBPe.BPe.infBPe.emit.enderEmit.xMun =
            bpe.bpe.infBPe.emit.enderEmit.xMun;
          dataBPe.BPe.infBPe.emit.enderEmit.UF =
            bpe.bpe.infBPe.emit.enderEmit.UF;

          dataBPe.BPe.infBPe.agencia.xNome = bpe.bpe.infBPe.agencia.xNome;
          dataBPe.BPe.infBPe.agencia.CNPJ = bpe.bpe.infBPe.agencia.CNPJ;
          dataBPe.BPe.infBPe.agencia.enderAgencia.xLgr =
            bpe.bpe.infBPe.agencia.enderAgencia.xLgr;
          dataBPe.BPe.infBPe.agencia.enderAgencia.nro =
            bpe.bpe.infBPe.agencia.enderAgencia.nro;
          dataBPe.BPe.infBPe.agencia.enderAgencia.xBairro =
            bpe.bpe.infBPe.agencia.enderAgencia.xBairro;
          dataBPe.BPe.infBPe.agencia.enderAgencia.cMun =
            bpe.bpe.infBPe.agencia.enderAgencia.cMun;
          dataBPe.BPe.infBPe.agencia.enderAgencia.xMun =
            bpe.bpe.infBPe.agencia.enderAgencia.xMun;
          dataBPe.BPe.infBPe.agencia.enderAgencia.UF =
            bpe.bpe.infBPe.agencia.enderAgencia.UF;

          dataBPe.BPe.infBPe.infPassagem.cLocOrig =
            bpe.bpe.infBPe.infPassagem.cLocOrig;
          dataBPe.BPe.infBPe.infPassagem.xLocOrig =
            bpe.bpe.infBPe.infPassagem.xLocOrig;
          dataBPe.BPe.infBPe.infPassagem.cLocDest =
            bpe.bpe.infBPe.infPassagem.cLocDest;
          dataBPe.BPe.infBPe.infPassagem.xLocDest =
            bpe.bpe.infBPe.infPassagem.xLocDest;
          dataBPe.BPe.infBPe.infPassagem.dhEmb =
            bpe.bpe.infBPe.infPassagem.dhEmb;
          dataBPe.BPe.infBPe.infPassagem.dhValidade =
            bpe.bpe.infBPe.infPassagem.dhValidade;
          dataBPe.BPe.infBPe.infPassagem.infPassageiro.xNome =
            bpe.bpe.infBPe.infPassagem.infPassageiro.xNome;
          dataBPe.BPe.infBPe.infPassagem.infPassageiro.tpDoc =
            bpe.bpe.infBPe.infPassagem.infPassageiro.tpDoc;
          dataBPe.BPe.infBPe.infPassagem.infPassageiro.nDoc =
            bpe.bpe.infBPe.infPassagem.infPassageiro.nDoc;

          dataBPe.BPe.infBPe.infViagem.cPercurso =
            bpe.bpe.infBPe.infViagem.cPercurso;
          dataBPe.BPe.infBPe.infViagem.xPercurso =
            bpe.bpe.infBPe.infViagem.xPercurso;
          dataBPe.BPe.infBPe.infViagem.tpViagem =
            bpe.bpe.infBPe.infViagem.tpViagem;
          dataBPe.BPe.infBPe.infViagem.tpServ = bpe.bpe.infBPe.infViagem.tpServ;
          dataBPe.BPe.infBPe.infViagem.tpAcomodacao =
            bpe.bpe.infBPe.infViagem.tpAcomodacao;
          dataBPe.BPe.infBPe.infViagem.tpTrecho =
            bpe.bpe.infBPe.infViagem.tpTrecho;
          dataBPe.BPe.infBPe.infViagem.dhViagem =
            bpe.bpe.infBPe.infViagem.dhViagem;

          dataBPe.BPe.infBPe.infValorBPe.vBP = bpe.bpe.infBPe.infValorBPe.vBP;
          dataBPe.BPe.infBPe.infValorBPe.tpDesconto =
            bpe.bpe.infBPe.infValorBPe.tpDesconto;
          dataBPe.BPe.infBPe.infValorBPe.xDesconto =
            bpe.bpe.infBPe.infValorBPe.xDesconto;
          dataBPe.BPe.infBPe.infValorBPe.vDesconto =
            bpe.bpe.infBPe.infValorBPe.vDesconto;
          dataBPe.BPe.infBPe.infValorBPe.vPgto =
            bpe.bpe.infBPe.infValorBPe.vPgto;
          dataBPe.BPe.infBPe.infValorBPe.vTroco =
            bpe.bpe.infBPe.infValorBPe.vTroco;

          if (bpe.bpe.infBPe.infValorBPe.Comp.length) {
            for (
              let index = 0;
              index < bpe.bpe.infBPe.infValorBPe.Comp.length;
              index++
            ) {
              dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp =
                bpe.bpe.infBPe.infValorBPe.Comp[index].tpComp;
              dataBPe.BPe.infBPe.infValorBPe.Comp.vComp =
                bpe.bpe.infBPe.infValorBPe.Comp[index].vComp;
              dataBPe.BPe.infBPe.infValorBPe.Comp.push({
                tpComp: dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp,
                vComp: dataBPe.BPe.infBPe.infValorBPe.Comp.vComp,
              });
            }
          } else {
            dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp =
              bpe.bpe.infBPe.infValorBPe.Comp.tpComp;
            dataBPe.BPe.infBPe.infValorBPe.Comp.vComp =
              bpe.bpe.infBPe.infValorBPe.Comp.vComp;
            dataBPe.BPe.infBPe.infValorBPe.Comp.push({
              tpComp: dataBPe.BPe.infBPe.infValorBPe.Comp.tpComp,
              vComp: dataBPe.BPe.infBPe.infValorBPe.Comp.vComp,
            });
          }

          dataBPe.BPe.infBPe.imp.ICMS.ICMS00.CST =
            bpe.bpe.infBPe.imp.ICMS.ICMS00.CST;
          dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vBC =
            bpe.bpe.infBPe.imp.ICMS.ICMS00.vBC;
          dataBPe.BPe.infBPe.imp.ICMS.ICMS00.pICMS =
            bpe.bpe.infBPe.imp.ICMS.ICMS00.pICMS;
          dataBPe.BPe.infBPe.imp.ICMS.ICMS00.vICMS =
            bpe.bpe.infBPe.imp.ICMS.ICMS00.vICMS;
          dataBPe.BPe.infBPe.imp.ICMS.ICMS00.indSN =
            bpe.bpe.infBPe.imp.ICMS.ICMS00.indSN;

          dataBPe.BPe.infBPe.pag.tPag = parseInt(bpe.bpe.infBPe.pag.tPag);

          dataBPe.BPe.infBPe.pag.tPag = String(dataBPe.BPe.infBPe.pag.tPag);
          dataBPe.BPe.infBPe.pag.vPag = bpe.bpe.infBPe.pag.vPag;

          this.$http
            .post("/bpe/reemissao-por-erro", dataBPe)
            .then((r) => {
              if (r.data.result) {
                this.message.text = r.data.result;
                this.message.snacshow = true;
                this.message.type = "success";
              } else if (r.data.error) {
                this.message.text = r.data.error;
                this.message.snacshow = true;
                this.message.type = "error";
              }
            })
            .catch((e) => {
              this.message.text = e;
              this.message.snacshow = true;
              this.message.type = "error";
            });
        });
      }

      this.$store.dispatch("activeLoader", false);
    },
  },
};
</script>

<style src="./style.vue"></style>
